import Vue from 'vue'
import { logger, inspect } from '@/utils/logger'
import { getDidUnderwritingRelatedInfoChange, IUpdateApplicantAddressPayload, MaritalStatus, postUpdateAddress, postUpdateApplicantFields, ResidenceType } from '@/services/api'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { i18n } from '@/utils/i18n'
import { ApiErrorHandler } from '@/utils/exception-handler'
import { sharedPagePaths } from '@/routes/sharedRoutes'
import { assertCtxIsType, assertCtxNotType } from '@/utils/assert-helper'

const HOME_NOT_FOUND = 'HOME_NOT_FOUND'
const INVALID_FLOOD_ZONE = 'INVALID_FLOOD_ZONE'
const UNKNOWN_FLOOD_ZONE = 'UNKNOWN_FLOOD_ZONE'
const BLOCKED_APPLICATION = 'BLOCKED_APPLICATION'
const PersonalInfoErrorCodes = new Map([
    ['INVALID_US_PHONE_NUMBER_ERROR', i18n.t('customValidationRules.notValidPhoneNumber')],
    ['INVALID_ADDRESS_ERROR', i18n.t('components.formFieldAddress.validation.addressNotFound')],
    ['AMBIGUOUS_PROPERTY_ADDRESS_ERROR', i18n.t('global.errors.confirmAddress', { addressType: 'property' })],
    ['AMBIGUOUS_PRIMARY_ADDRESS_ERROR', i18n.t('global.errors.confirmAddress', { addressType: 'primary' })],
    ['ADDRESS_VALIDATION_API_ERROR', i18n.t('global.errors.generic')],
    ['applicant_under_age', i18n.t('customValidationRules.underAge')],
    [HOME_NOT_FOUND, i18n.t('customValidationRules.homeNotFound')],
    [INVALID_FLOOD_ZONE, i18n.t('customValidationRules.invalidHomeLocationDueToFloodZone')],
    [UNKNOWN_FLOOD_ZONE, i18n.t('customValidationRules.unknownFloodZone')],
    ['PROHIBITED_COUNTY_ERROR', i18n.t('customValidationRules.prohibitedCounty')],
    ['PHONE_NUMBER_ALREADY_EXISTS', i18n.t('customValidationRules.phoneNumberAlreadyExists')],
    ['ERROR_APPLICANT_PHONE_CANNOT_BE_SHARED', i18n.t('customValidationRules.phoneNumberAlreadyExists')],
    ['ERROR_APPLICANT_PHONE_BELONGS_TO_PRIMARY', i18n.t('customValidationRules.phoneNumberBelongsToPrimary')],
    ['ERROR_APPLICANT_PHONE_BELONGS_TO_COAPPLICANT', i18n.t('customValidationRules.phoneNumberBelongsToCoapplicant')],
    ['EMAIL_ALREADY_EXISTS', i18n.t('customValidationRules.emailAlreadyExists')],
    ['SSN_COMPLETION_ERROR', i18n.t('customValidationRules.ssnCompletionError')],
    ['BAD_REQUEST', i18n.t('global.errors.badRequest')],
])

interface AddressComponents {
    addressUnit?: string
    addressStreet: string
    addressCity: string
    addressState: string
    addressPostalCode: string
}

interface ApplicantAddress {
    inputType: string
    addressComponents: AddressComponents
}

interface ApplicantInfo {
    firstName: string
    lastName: string
    emailAddress: string
    maritalStatus: MaritalStatus
    propertyAddressData: ApplicantAddress // Address of the property to UW, can be either primary or secondary
    personalAddressData: ApplicantAddress // Where the applicant lives, is always primary
    residenceType: ResidenceType // Residence type of the address to UW
    ssn: string
    applicantDOB: string
}

interface APIResponse<T> {
    data: {
        payload: {
            errors?: string[]
        } & T
        success: boolean
    }
}

const getAddressPayloadFromAddressComponents = (addressComponents: AddressComponents) => {
    return {
        addressApt: addressComponents.addressUnit?.trim() || undefined,
        addressStreet: addressComponents.addressStreet,
        addressCity: addressComponents.addressCity,
        addressState: addressComponents.addressState,
        addressPostalCode: addressComponents.addressPostalCode,
    }
}

const correctAddressComponentsIfAmbiguous = (addressComponents: AddressComponents, addressResponse: any) => {
    if (!addressComponents || !addressResponse) {
        logger.log(`No valid addressComponents or addressResponse found. Received addressComponents: ${inspect(addressComponents)}; Received addressResponse: ${inspect(addressResponse)}`)
        return
    }

    const addressAmbiguousError = addressResponse.data?.payload?.errors?.find((err: any) => err?.errorCode?.match(/AMBIGUOUS_(PROPERTY|PRIMARY)_ADDRESS_ERROR/))
    if (addressAmbiguousError) {
        addressComponents.addressUnit = addressAmbiguousError.secondaryAddressUnit
        addressComponents.addressStreet = addressAmbiguousError.addressStreet
        addressComponents.addressCity = addressAmbiguousError.addressCity
        addressComponents.addressState = addressAmbiguousError.addressState
        addressComponents.addressPostalCode = addressAmbiguousError.addressPostalCode
    }
}

export default Vue.extend({
    methods: {
        getDidUnderwritingInfoChange: async function (applicantInfo: any): Promise<boolean> {
            // @ts-ignore the assert takes care of this line and the next
            assertCtxIsType(this, 'errorText', 'string')
            // @ts-ignore
            this.errorText = ''
            logger.info(`applicantInfo: ${JSON.stringify(applicantInfo)}`)

            const postBody = this.basicInfoPostBody(applicantInfo)
            logger.info(`postBody: ${JSON.stringify(postBody)}`)

            const didUnderwritingInfoChangeResponse = await getDidUnderwritingRelatedInfoChange(postBody)
            const didUnderwritingInfoChange = didUnderwritingInfoChangeResponse.data.payload.didUnderwritingRelatedInfoChange
            logger.info(`didUnderwritingInfoChange: ${didUnderwritingInfoChange}`)
            return didUnderwritingInfoChange
        },
        maybeHandleErrors: async function (responses: APIResponse<any>[]) {
            const successfullySubmittedBasicInfo = responses.every((response) => response.data.success)

            if (successfullySubmittedBasicInfo) {
                return false // Return false indicating no errors were found
            }

            const allErrors = responses.flatMap((response) => response.data?.payload?.errors || [])

            // handle blocked application
            const errorsContainBlockedApplicationReason = allErrors.some((error) => error.errorCode === BLOCKED_APPLICATION)
            if (errorsContainBlockedApplicationReason) {
                logger.info(`Redirecting to thanks page due to application being blocked`)
                await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: 'blockedApplication' } })
                return true
            }

            // handle cannot find home
            const errorsContainHomeNotFoundReason = allErrors.some((error) => error.errorCode === HOME_NOT_FOUND)
            if (errorsContainHomeNotFoundReason) {
                logger.info(`Redirecting to thanks page due to not being able to find home (and thereby evaluate flood risk)`)
                await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: 'homeNotFound' } })
                return true
            }

            // handle invalid flood zone by redirecting to thanks
            const errorsContainFloodZoneReason = allErrors.some((error) => error.errorCode === INVALID_FLOOD_ZONE)
            if (errorsContainFloodZoneReason) {
                logger.info(`Redirecting to thanks page due to submitting a home in an invalid flood zone (backend has set status to ineligible)`)
                await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: 'invalidFloodZone' } })
                return true
            }

            const errorsContainUnknownFloodZoneReason = allErrors.some((error) => error.errorCode === UNKNOWN_FLOOD_ZONE)
            if (errorsContainUnknownFloodZoneReason) {
                logger.info(`Redirecting to thanks page due to submitting a home in an unknown flood zone (backend has set status to humanInvestigate)`)
                await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: 'unknownFloodZone' } })
                return true
            }

            let errorMessage = ''
            logger.info(`errors: ${JSON.stringify(allErrors)}`)

            allErrors.forEach((error: any) => {
                errorMessage += errorMessage.length > 0 ? '<br/>' : ''
                const errorCode = error.errorCode
                logger.info(`Received submit basic info error code ${errorCode}`)

                const errorCodeMap = PersonalInfoErrorCodes
                if (errorCodeMap.has(errorCode)) {
                    // check if the error code has a value in the map
                    const errorString = errorCodeMap.get(errorCode)
                    errorMessage += errorString
                    logger.info(`Submit basic info error code ${errorCode} has string value ${errorString}`)
                } else {
                    // the error code wasn't in the map
                    errorMessage += errorCodeMap.get('BAD_REQUEST')
                    logger.error(`Unknown personal info error code: ${errorCode}`)
                }
            })
            // @ts-ignore see this.errorText assert above
            this.errorText = errorMessage

            return true
        },
        submitBasicInfo: async function (applicantInfo: ApplicantInfo): Promise<boolean> {
            try {
                // @ts-ignore the assert takes care of this line and the next
                assertCtxIsType(this, 'errorText', 'string')
                // @ts-ignore
                this.errorText = ''
                const responses = []
                logger.info(`applicantInfo: ${JSON.stringify(applicantInfo)}`)

                let personalAddressResponse
                if (applicantInfo.personalAddressData) {
                    const personalAddressPostBody = this.addressPostBody(applicantInfo)
                    personalAddressResponse = await postUpdateAddress(personalAddressPostBody)
                    responses.push(personalAddressResponse)
                    logger.info(`applicant postUpdateAddress response: ${JSON.stringify(personalAddressResponse && personalAddressResponse.data)}`)
                }

                // Check and correct addresses if ambiguous
                const updatedBasicInfo = JSON.parse(appSessionStorage.getItem(localStorageKey.basicInfo) as string)
                if (updatedBasicInfo) {
                    correctAddressComponentsIfAmbiguous(updatedBasicInfo.personalAddressData?.addressComponents, personalAddressResponse)
                    // @ts-ignore the assert takes care of the next couple lines of logic, so we can safely ignore the typings
                    assertCtxNotType(this, 'updateBasicInfo', 'undefined')
                    // @ts-ignore
                    this.updateBasicInfo(updatedBasicInfo)
                }

                // Handle address errors, but continue submitting fields to avoid losing information
                const handledAddressErrors = await this.maybeHandleErrors(responses)

                const postBody = this.basicInfoPostBody(applicantInfo)
                logger.info(`postBody: ${JSON.stringify(postBody)}`)

                const basicInfoResponse = await postUpdateApplicantFields(postBody)
                logger.info(`basicInfo response: ${JSON.stringify(basicInfoResponse.data)}`)
                responses.push(basicInfoResponse)

                // Handle the invalid address errors and return
                if (handledAddressErrors) {
                    logger.info('returning successfullySubmittedBasicInfo false')
                    return false // Return false indicating there was an error
                }

                const handledBasicInfoErrors = await this.maybeHandleErrors(responses)
                if (handledBasicInfoErrors) {
                    logger.info('returning successfullySubmittedBasicInfo false')
                    return false // Return false indicating there was an error
                }
            } catch (error) {
                // @ts-ignore see this.errorText assert above
                this.errorText = ApiErrorHandler(error)
                logger.info('returning successfullySubmittedBasicInfo false')
                return false
            }
            logger.info('returning successfullySubmittedBasicInfo true')
            return true
        },
        basicInfoPostBody(applicantInfo: ApplicantInfo) {
            const postBody = {} as any

            if (applicantInfo.firstName && applicantInfo.lastName) {
                postBody.firstName = applicantInfo.firstName
                postBody.lastName = applicantInfo.lastName
            }

            if (applicantInfo.emailAddress) {
                postBody.email = applicantInfo.emailAddress
            }

            if (applicantInfo.ssn) {
                postBody.ssn = applicantInfo.ssn
            }

            if (applicantInfo.applicantDOB) {
                postBody.dateOfBirth = applicantInfo.applicantDOB
            }
            return postBody
        },
        addressPostBody(applicantInfo: Required<Pick<ApplicantInfo, 'propertyAddressData' | 'personalAddressData' | 'residenceType'>>): IUpdateApplicantAddressPayload {
            const personalAddressData = applicantInfo.personalAddressData
            return {
                ...getAddressPayloadFromAddressComponents(personalAddressData.addressComponents as AddressComponents),
                residenceType: ResidenceType.PRIMARY,
                isPropertyAddress: false,
            }
        },
    },
})
